import React, {useEffect, useRef} from "react"
import { Link } from "gatsby"


import BROKERAGE_ICON from "../../images/template/services/brokerage.jpg"
import CONSIGNMENT_ICON from "../../images/template/services/consignment.jpg"
import AUCTION_ICON from "../../images/template/services/auctions.jpg"
import PURCHASE_ICON from "../../images/template/services/purchase.jpg"

const ProcessSteps = () => {
  const tabs = useRef(null);

  useEffect(() => {
    $(tabs.current).easyResponsiveTabs({
      type: "horizontal",
      closed: false,
      tabidentify: "js-tabs-a", // The tab groups identifier
    })
  });


  return (
    <>
      <div className="row center mt6 mb8">
        <h2>Our Process to Buy & Sell Equipment</h2>
      </div>

      <div className="row center mt8 mb8 buy-sell-process">
        <div id="js-tabs-horizontal" ref={tabs}>
          <ul className="resp-tabs-list js-tabs-a resp-htabs">
            <li>
              <span className="thenumber">1</span>OnSite Inspection
            </li>
            <li>
              <span className="thenumber">2</span>Upfront Pricing
            </li>
            <li>
              <span className="thenumber">3</span>Selling Channel
            </li>
            <li>
              <span className="thenumber">4</span>Return On Investment
            </li>
          </ul>
          <div className="resp-tabs-container js-tabs-a">
            <div>
              <p>
                <p>
                  W
                  <span>
                    e have unmatched equipment expertise and knowledge on both
                    scientific instruments as well as pharmaceutical processing
                    and packaging equipment, which allows up to accurately
                    assess their industry-specific market demand and value.
                  </span>
                </p>
                <p>
                  Certified equipment specialists will inspect, photograph and
                  give a specifications report all on all of your surplus
                  assets. This allows our clients to focus on their work and not
                  emailing photos and list back and fourth.
                </p>
              </p>
            </div>
            <div>
              <p>
                <p>
                  Based on the initial inspection
                  <span>
                    {" "}
                    we provide our clients with either an upfront cash offer for
                    their equipment or pricing on consigned assets, depending on
                    the disposition method chosen by the seller. We sell all
                    equipment "as-is” and you will never have to pay storage or
                    maintenance fees on any equipment we sell.
                  </span>
                </p>
              </p>
            </div>
            <div>
              <div className="row">
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      {" "}
                      <img
                        src={BROKERAGE_ICON}
                        alt="Brokerage"
                        title="Brokerage"
                        width="300"
                        height="300"
                      />{" "}
                    </div>
                    <div className="xsmall-8 columns left-align">
                      <h5 className="caps blue mb0">Brokerage</h5>
                      <p>
                        Our level of understanding, on-the-ground coverage, and
                        attention to detail provides clients with a distinct
                        competitive advantage. Our knowledgeable sales team will
                        ensure consistency and quality&mdash;no matter the
                        location or asset type.
                      </p>
                      <ul>
                        <li>Understand the market trends</li>
                        <li>Relationships and experience</li>
                        <li>Maximize returns</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      {" "}
                      <img
                        src={PURCHASE_ICON}
                        alt="Outright Purchase"
                        title="Outright Purchase"
                        width="300"
                        height="300"
                      />{" "}
                    </div>
                    <div className="xsmall-8 columns left-align">
                      <h5 className="caps blue mb0">Outright Purchase</h5>
                      <p>
                        Surplus Solutions will buy a single piece of equipment
                        or an entire facility. Please contact our team regarding
                        your surplus equipment.
                      </p>
                      <ul>
                        <li>Immediate Response</li>
                        <li>Hassle free transaction</li>
                        <li>Coordinate logistics to or facility</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      {" "}
                      <img
                        src={CONSIGNMENT_ICON}
                        alt="Consignment"
                        title="Consignment"
                        width="300"
                        height="300"
                      />{" "}
                    </div>
                    <div className="xsmall-8 columns left-align ">
                      <h5 className="caps blue mb0">Consignment</h5>
                      <p>
                        Our low cost warehouse program allows our customers to
                        free up space until their equipment is sold. Surplus
                        Solutions provides a safe and secure facility while
                        assets go through sales process.
                      </p>
                      <ul>
                        <li>Manage and Track Assets</li>
                        <li>Free up time and space</li>
                        <li>Sell your equipment</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      <img alt="Auctions" src={AUCTION_ICON} width="300" height="300" />
                      <br />{" "}
                    </div>
                    <div className="xsmall-8 columns left-align">
                      <h5 className="caps blue mb0">Auctions</h5>
                      <p>
                        Auctions provide our clients with the ideal symmetry
                        between maximum returns and quick turnaround. We work
                        closely with our clients to determine the specific type
                        of auction to best meet their needs.
                      </p>
                      <ul>
                        <li>Onsite, Webcast, Sealed Bids</li>
                        <li>Pre-Aucton planning and consulting</li>
                        <li>Global audience of buyers</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>
                <div>
                  Regardless of the disposition method chosen we cover all
                  upfront costs of logistics and inventory. Our clients never
                  receive a bill for selling equipment. With an outright
                  purchase we pay for the assets in full before they leave your
                  facility and under consignment we start the inventory and
                  marketing process the day your equipment hits our loading
                  dock.
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProcessSteps
