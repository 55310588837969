import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import ProcessSteps from "../components/sell-equipment/process-steps"
import InquiryForm from "../components/buy-equipment/inquiry-form"

const SellEquipment = () => (
  <Layout className="main inner-page-with-banner">
    <SEO
      title={`Best Place To Sell Used Lab Equipment`}
      description={`At Surplus Solutions, buying and selling your equipment is now simplified. Our trained sales experts are ready and waiting to guide you through the process of selling your surplus equipment. Complete a form and tell us about your surplus assets. A member of our team will be in touch shortly.`}
    />

    <div className="row column center mt8">
      <h1>We Buy and Sell Used Laboratory and Biotech Equipment.</h1>
      <p>
        <p>
          <p>
            Got surplus equipment? Buying and selling your equipment is now
            simplified. Our trained sales experts are ready and waiting to guide
            you through the process of selling your surplus equipment. It can be
            as simple as 1-2-3-4. Complete the form below and tell us about your
            surplus assets and a member of our team will be in touch shortly.
          </p>
        </p>
      </p>

      <InquiryForm
        title={`Tell us about your equipment: `}
        formSource={`Sell Equipment`}
      />

      <ProcessSteps />
    </div>
  </Layout>
)

export default SellEquipment
